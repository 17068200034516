import { useEffect, useState, useContext, createContext } from 'react'
import { apiCall } from '@utils'
import { WorkspaceContext } from './Workspace'

export const UsersContext = createContext()

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState(null)
  const { workspace_id } = useContext(WorkspaceContext)

  const getUsers = async () => {
    const result = await apiCall(`${workspace_id}/user`)
    setUsers(result.body)
  }

  useEffect(() => {
    if (workspace_id) getUsers()
  }, [workspace_id])

  return <UsersContext.Provider value={{ users, setUsers }}>{children}</UsersContext.Provider>
}
