// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'More Gooder',
    appLogoImage: require('@src/assets/images/logo/logo.svg').default,
    appLogoImageGray: require('@src/assets/images/logo/logo-gray.svg').default,
    appLogoImageWhite: require('@src/assets/images/logo/logo-white.svg').default,
    appLogoImageCollapsed: require('@src/assets/images/logo/logo-collapsed.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button,
  }
  // ,
  // api: {
  //   baseUrl: 'https://moregooder-api.herokuapp.com'
  // }
}

export default themeConfig
