import { useEffect, useState, useContext, createContext } from 'react'
import { apiCall } from '@utils'
import { UserContext } from './User'
import { UserCheck } from 'react-feather'
export const WorkspaceContext = createContext()

export const WorkspaceProvider = ({ children }) => {
  const [loadingWorkspaceList, setLoadingWorkspaceList] = useState(true)
  const [workspaceList, setWorkspaceList] = useState(null)
  const [workspace, setWorkspace] = useState(null)
  const [workspace_id, setWorkspace_id] = useState(null)
  const [loadingWorkspace, setLoadingWorkspace] = useState(true) // prevents infinite loops!!!!

  const { user, setUser, setPermissions } = useContext(UserContext)

  const setWorkspaceState = (w) => {
    setWorkspace_id(w.id)
    setWorkspace(w)
    localStorage.setItem('workspace_id', w.id)
    setLoadingWorkspace(false)
  }

  const findWorkspace = (id) => {
    const w = workspaceList && workspaceList.find((w) => w.id === id)
    if (w) {
      setWorkspaceState(w)
      return w
    } else {
      const localW = workspaceList && workspaceList.find((w) => w.id === localStorage.getItem('workspace_id'))
      if (localW) {
        setWorkspaceState(localW)
        return localW
      } else if (workspaceList[0]) {
        setWorkspaceState(workspaceList[0])
        return workspaceList[0]
      } else {
        setWorkspace_id(null)
        setWorkspace(null)
        localStorage.setItem('workspace_id', null)
        return false
      }
    }
  }

  const getWorkspaceById = async (id) => {
    setLoadingWorkspace(true)
    const w = findWorkspace(id)
    if (!w && localStorage.getItem('workspace_id')) {
      findWorkspace(localStorage.getItem('workspace_id'))
    }
    setLoadingWorkspace(false)
  }

  const getWorkspaceList = async () => {
    setLoadingWorkspaceList(true)
    let w = false
    if (workspaceList) w = workspaceList.find((w) => w.id === workspace_id)
    if (!workspaceList || (!w && workspaceList)) {
      setLoadingWorkspaceList(true)
      if (user.workspaces.length > 0) {
        const res = await apiCall('/workspace')
        setWorkspaceList(res.body)
      } else {
        setWorkspaceList([])
      }
    }
    setLoadingWorkspaceList(false)
  }

  // useEffect(async () => {
  //   if (workspace_id) {
  //     const res = await apiCall(`/${workspace_id}/user/${user.id}/role`)
  //     setPermissions(res.body.topics)
  //   }
  // }, [workspace_id]) TODELETE

  useEffect(() => {
    if (user) {
      getWorkspaceList()
    }
  }, [user, workspace_id])

  return (
    <WorkspaceContext.Provider
      value={{
        getWorkspaceById,
        workspace_id,
        setWorkspace_id,
        workspace,
        setWorkspace,
        workspaceList,
        setWorkspaceList,
        loadingWorkspace,
        setLoadingWorkspace
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  )
}
